import $ from 'jquery'
import _ from 'lodash'

// -------------- Youtube + Flash alerts --------------

// prettier-ignore
export const constructIframe = (videoID) => {
    return (
        '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + videoID + '?rel=0&amp;showinfo=0&amp;autoplay=1" frameborder="0" allowfullscreen></iframe>'
    )
}

// prettier-ignore
export const constructFlashMessage = (type, message) => {
    return (
        '<div class="alert alert-' + type + ' alert-dismissible" style="display: none;" role="alert">' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
            '</button>' +
            '<strong>' + message + '</strong>' +
        '</div>'
    )
}

export const FLASH_MESSAGE_TYPE = {
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'danger',
    SUCCESS: 'success',
}

export const FLASH_MESSAGE_FADE_SPEED = 1000 // ms

export const addFlashMessage = (type, message, $placing) => {
    const alert = constructFlashMessage(type, message)
    $placing.append(alert)
    $placing.find('.alert').fadeIn(FLASH_MESSAGE_FADE_SPEED)
}

export const addFlashMessageSuccess = (message, $placing) => {
    const alert = constructFlashMessage(FLASH_MESSAGE_TYPE.SUCCESS, message)
    $placing.append(alert)
    $placing.find('.alert').fadeIn(FLASH_MESSAGE_FADE_SPEED)
}

export const addFlashMessageInfo = (message, $placing) => {
    const alert = constructFlashMessage(FLASH_MESSAGE_TYPE.INFO, message)
    $placing.append(alert)
    $placing.find('.alert').fadeIn(FLASH_MESSAGE_FADE_SPEED)
}

export const addFlashMessageWarning = (message, $placing) => {
    const alert = constructFlashMessage(FLASH_MESSAGE_TYPE.WARNING, message)
    $placing.append(alert)
    $placing.find('.alert').fadeIn(FLASH_MESSAGE_FADE_SPEED)
}

export const addFlashMessageError = (message, $placing) => {
    const alert = constructFlashMessage(FLASH_MESSAGE_TYPE.ERROR, message)
    $placing.append(alert)
    $placing.find('.alert').fadeIn(FLASH_MESSAGE_FADE_SPEED)
}

export const redirectAfterDelay = (url, delay) => {
    setTimeout(() => {
        window.location.href = url
    }, delay)
}

// -------------- Form helpers --------------

export const elementExists = (element) => $(element).length > 0

export const $elementExists = ($element) => elementExists($element[0])

export const elementIsVisible = (element) => $elementIsVisible($(element))

export const $elementIsVisible = ($element) => $element.is(':visible') && !$element.is(':hidden')

export const elementHasVal = (element) => $elementHasVal($(element))

export const $elementHasVal = ($element) => _.reduce([$selectIsSelected($element), $radioIsChecked($element), $checkboxIsChecked($element), $rangeIsFilled($element), $inputIsFilled($element)], (accum, result) => accum || result, false)

// ----- <Textarea> -----
export const elementIsTextArea = (element) => $(element).is('textarea')

// ----- <Select> -----
export const elementIsSelect = (element) => $elementIsSelect($(element))

export const $elementIsSelect = ($element) => $element.is('select')

export const $selectIsSelected = ($element) => $elementIsSelect($element) && !!$element.find(':selected').val()

// ----- <Radio> -----
export const elementIsRadio = (element) => $elementIsRadio($(element))

export const $elementIsRadio = ($element) => $element.is(':radio')

export const $radioIsChecked = ($element) => $elementIsRadio($element) && $element.filter(':checked').val()

// ----- <Checkbox> -----
export const elementIsCheckbox = (element) => $elementIsCheckbox($(element))

export const $elementIsCheckbox = ($element) => $element.is(':checkbox')

export const $checkboxIsChecked = ($element) => $elementIsCheckbox($element) && $element.filter(':checked').val()

// ----- <Date> -----
export const elementIsDate = (element) => $(element).attr('type') === 'date'

// ----- <Range> -----
export const elementIsRange = (element) => $elementIsRange($(element))

export const $elementIsRange = ($element) => $element.attr('type') === 'range'

export const $rangeIsFilled = ($element) => $elementIsRange($element) && true // TODO make $element.val() >= min && $element.val() <= max

// ----- <Input> -----
export const elementIsInput = (element) => $elementIsInput($(element))

export const $elementIsInput = ($element) => ['text', 'tel', 'number', 'email', 'password', 'hidden', 'date'].includes($element.attr('type')) // Be aware of 'hidden' !!!

export const $inputIsFilled = ($element) => $elementIsInput($element) && _.trim($element.val()) !== ''

// ----- Helpers -----
export const removeHtml5Validation = (element) => $(element).attr('novalidate', 'novalidate')

export const $disableElement = ($element) => $element.attr('disabled', true)

export const $readonlyElement = ($element) => $element.attr('readonly', true)

export const $enableElement = ($element) => $element.attr('disabled', false) && $element.attr('readonly', false)

export const $clearElement = ($element) => $element.val('')

export const $elementHashVal = ($element) => [$selectIsSelected($element), $radioIsChecked($element), $checkboxIsChecked($element), $element.val()].join()

export const $hideElement = ($element) => $element.hide()

export const $showElement = ($element) => $element.show()
